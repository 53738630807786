import {onFind} from "@elements/init-modules-in-scope";
import { hasClass, findIn, find, addClass, removeClass } from "@elements/dom-utils";

const isMobile = () => matchMedia('(max-width: 1199px)').matches;

const stickyNavElement = find("#PortalHeroAreaNav")
const portalHeroElement = find(".js-portal-hero")

export function init() {
    onFind('.header', function (header) {
        const headerHeight = 130;
        let lastScrollY = 0;
        const delta = 0;
        function scrolled() {
            let verticalScroll = window.scrollY;
            let nav = findIn('.js-mega-nav__toggle', header);

            if (Math.abs(lastScrollY - verticalScroll) > delta && isMobile()) {
                if (verticalScroll > lastScrollY && verticalScroll > headerHeight) {
                    if(!hasClass('is-open', nav)) {
                        header.classList.add("header--up");

                        if(stickyNavElement && portalHeroElement && portalHeroElement.getBoundingClientRect().top < 0) {
                            addClass('hero-area__nav--up', stickyNavElement)
                        }
                    }
                }
                else if (verticalScroll < lastScrollY) {
                    header.classList.remove("header--up");

                    // only remove the class if i havent scrolled past the area with class js-portal-hero
                    if(stickyNavElement && portalHeroElement && portalHeroElement.getBoundingClientRect().bottom > 0) {
                        removeClass('hero-area__nav--up', stickyNavElement)
                    }
                }
                lastScrollY = verticalScroll
            }
        }

        let didScroll = false;
        window.addEventListener("scroll", function(e){
            didScroll = true;
        });

        setInterval(function() {
            if (didScroll) {
                scrolled();
                didScroll = false;
            }
        }, 250)
    });
}