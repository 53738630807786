import {on, find, findAllIn, findIn, empty} from '@elements/dom-utils';
import {onFind} from "@elements/init-modules-in-scope";
import asyncAppend from "@elements/async-append";
import fetch from '@elements/fetch';
import Modal from 'bootstrap/js/dist/modal';

export function init () {
    onFind('.js-ajax-modal', function (element) {
        let modalSelector = element.getAttribute('data-ajax-modal-target');
        let modal = find(modalSelector);

        if (modal) {
            let modalInit = new Modal(modal);

            on('click', function () {
                let url = element.getAttribute('data-ajax-modal-content-url');

                modalInit.show();

                asyncAppend({
                    target: findAllIn('.js-ajax-modal__content', modal),
                    loading: findAllIn('.js-ajax-modal__loading', modal)
                }, fetch(url));
            }, element);

            let close = findIn('.js-ajax-modal__close', modal);
            on('click', function() {
                modalInit.hide();
            }, close);

            on('hidden.bs.modal', function () {
                empty(findIn('.js-ajax-modal__content', modal));
            }, modal);
        } else {
            console.warn(`.js-ajax-modal "${modalSelector}" not found`)
        }
    });
}
