import {onFind} from "@elements/init-modules-in-scope";
import {on, hasClass, findIn, find, addClass, removeClass, toggleClass, closest, findAllIn, setAttribute} from "@elements/dom-utils";
const isMobile = () => matchMedia('(max-width: 1199px)').matches;
export function init() {
    onFind('.js-mega-nav', function (nav) {
        let toggle = findIn('.js-mega-nav__toggle', nav);
        let collapse = findIn('.js-mega-nav__collapse', nav);
        let header = find('.js-header')

        // todo aria attributes
        on('click', function () {
            toggleClass('is-open', toggle);
            toggleClass('is-open', collapse);

            if(header) toggleClass('nav-is-open', header)
        }, toggle);

        findAllIn('.js-mega-nav__list', nav).map((list) => {
            let listItems = findAllIn('.js-mega-nav__list-item', list);
            let itemToggles = findAllIn('.js-mega-nav__list-toggle', list);

            if (itemToggles) {
                itemToggles.map((toggle) => {
                    on('click', function (evt) {
                        evt.preventDefault();
                        let parentItem = closest('.js-mega-nav__list-item', toggle);
                        if (hasClass('is-open', parentItem)) {
                            if (isMobile()) {
                                hideItem(parentItem)
                            } else {
                                hideItem(listItems);
                            }
                        } else {
                            if (isMobile()) {
                                showItem(parentItem);
                            } else {
                                hideItem(listItems);
                                showItem(parentItem);

                                window.addEventListener("click", function _listener(evt) {
                                    if (!parentItem.contains(evt.target)) {
                                        removeClass('is-open', parentItem);

                                        window.removeEventListener("click", _listener, true);
                                    }
                                }, true);
                            }

                        }
                    }, toggle);
                })
            }
        });
    });
}

function hideItem(item) {
    if(Array.isArray(item)) {
        item.forEach((i) => {
            removeClass('is-open', i)
            setAttribute('aria-expanded', false, i);
        })
    } else {
        removeClass('is-open', item);
        setAttribute('aria-expanded', false, item);
    }
}

function showItem(item) {
    if(Array.isArray(item)) {
        item.forEach((i) => {
            addClass('is-open', i);
            setAttribute('aria-expanded', true, i);
        })
    } else {
        addClass('is-open', item);
        setAttribute('aria-expanded', true, item);
    }
}
