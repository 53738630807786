import {onFind} from "@elements/init-modules-in-scope";
import {on, hasClass, findIn, addClass, removeClass, toggleClass, closest, findAllIn, setAttribute} from "@elements/dom-utils";
import BlazeSlider from 'blaze-slider';

const isMobile = matchMedia('(max-width: 1200px)').matches || false;

export function init() {
    onFind('.blaze-slider.teaser-slider', function (baseElement) {
        const teaserSlider = new BlazeSlider(baseElement, {
            all: {
                loop: false,
                slidesToScroll: 1,
                slideGap: '20px',
                slidesToShow: 2,
                draggable: true,
            },
            '(min-width: 768px)': {
                loop: false,
                slideGap: '40px',
                slidesToShow: 3,
            },
            '(min-width: 1200px)': {
                slideGap: '60px',
                slidesToShow: 4,
            },
        });
    });

    onFind('.blaze-slider.offer-teaser-slider', function (baseElement) {

        let slidesToShow = 2.5;
        // if there is less than 4 teasers
        if (matchMedia('(min-width: 768px)').matches) {
            let teasers = findAllIn('.highlight-teaser', baseElement);
            if (teasers.length < 4) {
                slidesToShow = 3;
                let prevButton = findIn('.js-blaze-prev', baseElement);
                let nextButton = findIn('.js-blaze-next', baseElement);
                let blazeContainer = findIn('.js-blaze-container', baseElement);
                prevButton.style.display = "none";
                nextButton.style.display = "none";
                addClass('hide-gradient', blazeContainer);
            }
            if (teasers.length < 2) {
                let blazeContainer = findIn('.js-blaze-container', baseElement);
                addClass('w-50', blazeContainer);
            }
        }

        new BlazeSlider(baseElement, {
            all: {
                loop: false,
                slidesToScroll: 1,
                slidesToShow: 2,
                draggable: true,
            },
            '(min-width: 990px)': {
                slidesToShow: slidesToShow,
            },
        });

    });

    onFind('.blaze-slider.award-teaser-slider', function (baseElement) {
        new BlazeSlider(baseElement, {
            all: {
                loop: false,
                slidesToShow: 1.5,
                slideGap: '20px'
            },
            '(min-width: 768px)': {
                loop: false,
                slidesToShow: 2.5,
                slideGap: '20px'
            },
            '(min-width: 990px)': {
                loop: false,
                slidesToShow: 4,
                slideGap: '60px'
            },
        });
    });


    onFind('.blaze-slider.teaser-row-slider', function (baseElement) {
         new BlazeSlider(baseElement, {
            all: {
                slideGap: '0px'
            },
        });
        });

    onFind('.blaze-slider.image-slider', function (baseElement) {
        const imageSlider = new BlazeSlider(baseElement, {
            all: {
                loop: true,
                slidesToScroll: 1,
                slidesToShow: 1,
                draggable: true,
            },
        });

        findAllIn('.blaze-track', baseElement).map((slider) => {
            let totalCount = findAllIn('.blaze-track > div', slider).length;

            let singleCount = 1;
            let counter = "<span class='single-count'>" + singleCount + "</span> / <span class='total-count'>" + totalCount + "</span>";
            if(document.getElementById('counter')) {
                document.getElementById('counter').innerHTML = counter;
            }

            imageSlider.onSlide(
                (pageIndex) => {
                    singleCount = pageIndex + 1;
                    let counter = "<span class='single-count'>" + singleCount + "</span> / <span class='total-count'>" + totalCount + "</span>";
                    if(document.getElementById('counter')) {
                        document.getElementById('counter').innerHTML = counter;
                    }
                }
            );
        });
    });

    if(isMobile) {
        onFind('.blaze-slider.image-teaser-slider', function (baseElement) {
            new BlazeSlider(baseElement, {
                all: {
                    loop: false,
                    slidesToScroll: 1,
                    slideGap: '20px',
                    slidesToShow: 1,
                    draggable: true,
                },
                '(min-width: 992px)': {
                    slidesToShow: 2,
                },
            });
        });
    }
}