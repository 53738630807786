import { onFind } from "@elements/init-modules-in-scope";
import { on } from "@elements/dom-utils";

export function init() {
    onFind(".row.js-contains-roomteaser", (container) => {
        onFind('.js-room-teaser a', (link) => {
            on('click', (e) => {
                e.preventDefault()
                container.remove() // <- also removes hidden input field with room id

                removeUrlParamAndValue('room')
            }, link)
        })
    })
}

function removeUrlParamAndValue(param) {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    console.log("params", params)
    params.delete(param);

    history.pushState({}, null,`${window.location.origin}${window.location.pathname}${params.size > 0 ? '?' : ''}${params}`)
}