
import {on, addClass} from '@elements/dom-utils';
import {onFind} from "@elements/init-modules-in-scope";
export function init () {
    onFind('.js-room-teaser', function (roomTeaser) {
        onFind('.js-room-teaser__close', function (closeButton) {
            on('click', function () {
                    addClass('d-none', roomTeaser);
            }, closeButton);
        });
    });
}