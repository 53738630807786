import {onFind} from "@elements/init-modules-in-scope";

import * as toc from '@elements/toc';
toc.init({linkClass: 'visually-hidden-focusable'});

import * as skipLinks from '@elements/skip-links';
skipLinks.init();

import * as lazyImg from '@elements/lazy-img';
lazyImg.init();

import * as responsiveTable from './responsive-table';
responsiveTable.init();

import * as teamOverlay from './team-overlay';
teamOverlay.init();


import * as hideOnScroll from './hide-on-scroll.js';
hideOnScroll.init();

import * as megaNav from './main-nav.js';
megaNav.init();


import * as ajaxModal from './ajax-modal';
ajaxModal.init();

import * as roomTeaser from './room-teaser';
roomTeaser.init();

import * as inquiryFormRooms from "./inquiry-form-rooms";
inquiryFormRooms.init()

import * as lightbox from '@elements/lightbox';
lightbox.init({
    plugins: ['video']
});

import * as floatingLabels from '@elements/floating-labels';
floatingLabels.init();

import * as slider from './slider';
slider.init();

import * as portalHero from './portal-hero';
portalHero.init();

import * as hashCash from './hashCash';
hashCash.init();

import * as conditionalForm from './conditional-form';
conditionalForm.init();


import Collapse from 'bootstrap/js/dist/collapse';
onFind('[data-bs-toggle="collapse"]',function (element) {
    if (element.getAttribute('data-collapse-xs')) {
        if (matchMedia('(max-width: 767px)').matches) {
            new Collapse(element.getAttribute('data-bs-target'), {
                toggle: false
            })
        }
    } else {
        new Collapse(element.getAttribute('data-bs-target'), {
            toggle: false
        });
    }
});

import Dropdown from 'bootstrap/js/dist/dropdown';
onFind('[data-bs-toggle="dropdown"]',function (element) {
    new Dropdown(element);
});

import * as consentOverlay from '@elements/consent-overlay';
consentOverlay.init({
        cmpTool: 'cookiebot',
        revalidateEvent: 'CookiebotOnConsentReady'
    },
    {
        base: '.js-consent',
        template: '.js-consent__template',
        dataset: 'consent',
        button: '.js-consent__accept-btn'
    });


// Todo: Add common JS that is shared between all page types here

// import * as renderTemplate from './render-template';
// renderTemplate.init();
//
// if you use this, also use the associated CSS (content-visibility.scss)
import * as contentVisibility from './content-visibility';
contentVisibility.init();

import * as tracking from "@elements/tracking";
let options = { debug: false }; // optional options
tracking.initGtmEventTracking(options); // for GTM tracking

import * as customTracking from './tracking'
customTracking.init()