"use strict";

import {onFind} from "@elements/init-modules-in-scope";
import {on, findIn} from "@elements/dom-utils";
import {serializeArray} from "./helpers/dom-utils-extended";

export function init() {
    onFind('.js-portal-hero', function (portalHeroNav) {

        let overflowElement = document.documentElement;
        let baer = findIn('#baer', portalHeroNav);
        let loewe = findIn('#loewe', portalHeroNav);
        let navbarElement = findIn('#PortalHeroAreaNav', portalHeroNav);
        onFind('.baerAnchor', function (baerNav) {
            on('click', function (baerNav) {
                let bodyRect = overflowElement.getBoundingClientRect();
                let anchorRect = baer.getBoundingClientRect();
                let navbarHeight = navbarElement.offsetHeight;

                document.documentElement.scrollTop = ((anchorRect.top - bodyRect.top) - navbarHeight) -60;
                console.log(document.documentElement.scrollTop);
            }, baerNav)
        })

        onFind('.loeweAnchor', function (loeweNav) {
            on('click', function (loeweNav) {
                let bodyRect = overflowElement.getBoundingClientRect();
                let anchorRect = loewe.getBoundingClientRect();
                let navbarHeight = navbarElement.offsetHeight;

                document.documentElement.scrollTop = ((anchorRect.top - bodyRect.top) - navbarHeight) -60;
                console.log(document.documentElement.scrollTop);
            }, loeweNav)
        })
    })
}
