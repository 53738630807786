import {onFind} from "@elements/init-modules-in-scope";
import Modal from 'bootstrap/js/dist/modal';

export function init () {
    onFind('.js-team-overlay', function(element) {
        if(element){
            let modalInstance = element.dataset.instance;
            let teamOverlay = new Modal(element);
            if (!sessionStorage.getItem('info' + modalInstance)) {
                teamOverlay.show();
            }
            element.addEventListener('hide.bs.modal', function () {
                sessionStorage.setItem('info' + modalInstance,'closed');
            });
        }
    });

    onFind('.js-modal-open')
}