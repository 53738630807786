import { onFind } from '@elements/init-modules-in-scope';
import { on, findIn, closest } from '@elements/dom-utils';

const selectors = {
    watchInput: '.js-tracking__watch-input',
    watchHiddenInput: '.js-tracking__watch-input--hidden',
    manualSubmissionForms: '.js-elhc-form',
    altField: '.js-datepicker__alt-field',
};

const dataAttributes = {
    associatedTrackingKey: 'data-associated-tracking-key',
    associatedTrackingField: 'data-associated-tracking-field'
};

export function init() {

    onFind(selectors.watchInput, (element) => {
        const associatedTrackingKey = element.getAttribute(dataAttributes.associatedTrackingKey);
        const associatedTrackingField = element.getAttribute(dataAttributes.associatedTrackingField);

        handleValueChange({element, associatedTrackingKey, associatedTrackingField}); // initial fill
        on('change', () => {
            handleValueChange({element, associatedTrackingKey, associatedTrackingField});
        }, element);
    })

    // for all forms that use manual submission
    on('load', (event) => {
        const allForms = document.querySelectorAll(selectors.manualSubmissionForms);
        allForms.forEach((formElement) => {
            on('form-validation.submit', (event) => pushToDatalayer('submit-inquiry'), formElement)
        })
    }, window);
}

function handleValueChange({element, associatedTrackingKey, associatedTrackingField}) {
    if(isOfType(element, ['dateFrom', 'dateTo'])) {
        let dateString = _trackingData[associatedTrackingKey].datalayer.date;
        dateString = dateString === undefined || dateString.length == 0 ? '-' : dateString;

        let dateFrom = _trackingData[associatedTrackingKey].datalayer.date_start;
        let dateTo = _trackingData[associatedTrackingKey].datalayer.date_end;
        let diffDays = _trackingData[associatedTrackingKey].datalayer.night_count;
        let dateFromDiff = document.querySelector(selectors.altField + '[name="dateFrom"]').value;
        let dateToDiff = document.querySelector(selectors.altField + '[name="dateTo"]').value;
        const diffTime = Math.abs(new Date(dateToDiff) - new Date(dateFromDiff));
        diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        _trackingData[associatedTrackingKey].datalayer.night_count = diffDays;


        if(isOfType(element,['dateFrom'])) {
            if(element.value == undefined || element.value.length == 0) {
                dateFrom = reformatDate(getHiddenDatepickerInputValue(element));

            } else {
                dateFrom = reformatDate(element.value);

            }
        } else {
            if(element.value == undefined || element.value.length == 0) {
                dateTo = reformatDate(getHiddenDatepickerInputValue(element));
            } else {
                dateTo = reformatDate(element.value);
            }
        }

        _trackingData[associatedTrackingKey].datalayer.date_start = dateFrom;
        _trackingData[associatedTrackingKey].datalayer.date_end = dateTo;

    } else if(isOfType(element, ['room_count', 'accommodation'])) {
        if(element.type === 'radio' || element.type === 'checkbox') {
            if(element.checked) {
                _trackingData[associatedTrackingKey].datalayer[associatedTrackingField] = element.value;
            } else {
                _trackingData[associatedTrackingKey].datalayer[associatedTrackingField] = ''; // for setting the default values
            }
        } else {
            _trackingData[associatedTrackingKey].datalayer[associatedTrackingField] = element.value;
        }
    }
}

function isOfType(element, fieldTypes) {
    return fieldTypes.some(fieldType => element.getAttribute(dataAttributes.associatedTrackingField) === fieldType);
}

function getHiddenDatepickerInputValue(element) {
    return findIn(selectors.watchHiddenInput, closest('.js-datepicker', element))?.value || '';
}

function reformatDate(inputString) {
    let date;

    if(/^[a-zA-Z]+\s\d{1,2},\s\d{4}\s\d{1,2}:\d{2}$/.test(inputString)) { // "Month DD, YYYY HH:mm" format
        date = new Date(inputString);
    } else if(/^\d+\.\d+\.\d{4}$/.test(inputString)) { // "DD.MM.YYYY" format
        const parts = inputString.split('.');
        date = new Date(`${parts[2]}/${parts[1]}/${parts[0]}`);
    } else if(/^\d{4}-\d+-\d+$/.test(inputString)) { // "YYYY-MM-DD" format
        const parts = inputString.split('-');
        date = new Date(`${parts[0]}/${parts[1]}/${parts[2]}`);
    } else if(/^\d+-\d+-\d{4}$/.test(inputString)) { // "DD-MM-YYYY" format
        const parts = inputString.split('-');
        date = new Date(`${parts[2]}/${parts[1]}/${parts[0]}`);
    }  else if(/^\d+\/\d+\/\d{4}$/.test(inputString)) { // "MM/DD/YYYY" format
        const parts = inputString.split('/');
        date = new Date(`${parts[0]}/0${parts[1]}/${parts[2]}`);
    }  else {
        return inputString;
    }

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');

    return `${year}${month}${day}`;
}

function pushToDatalayer(key) {
    // get data from _trackingData
    if(window.dataLayer && _trackingData) {
        const dataToPush = _trackingData[key].datalayer;
        dataToPush && window.dataLayer.push(dataToPush);
    }
}